const getApiEnvironment = () => {
  const currentVercelBranch = process.env.VERCEL_GIT_COMMIT_REF

  if (currentVercelBranch) {
    if (process.env.FIELDLAB_ENV_BRANCH_QA === currentVercelBranch) {
      return {
        clientId: process.env.QA_FIELDLAB_ID,
        clientSecret: process.env.QA_FIELDLAB_SECRET,
        baseUrl: process.env.QA_FIELDLAB_API_URL,
        apiUrl:
          process.env.QA_FIELDLAB_PREFIX ||
          `${process.env.QA_FIELDLAB_API_URL}/user`,
      }
    }

    if (process.env.FIELDLAB_ENV_BRANCH_TEST === currentVercelBranch) {
      return {
        clientId: process.env.TEST_FIELDLAB_ID,
        clientSecret: process.env.TEST_FIELDLAB_SECRET,
        baseUrl: process.env.TEST_FIELDLAB_API_URL,
        apiUrl:
          process.env.TEST_FIELDLAB_PREFIX ||
          `${process.env.TEST_FIELDLAB_API_URL}/user`,
      }
    }
    if (process.env.FIELDLAB_ENV_BRANCH_STABLE === currentVercelBranch) {
      return {
        clientId: process.env.STABLE_FIELDLAB_ID,
        clientSecret: process.env.STABLE_FIELDLAB_SECRET,
        baseUrl: process.env.STABLE_FIELDLAB_API_URL,
        apiUrl:
          process.env.STABLE_FIELDLAB_PREFIX ||
          `${process.env.STABLE_FIELDLAB_API_URL}/user`,
      }
    }
  }

  return {
    clientId: process.env.FIELDLAB_ID,
    clientSecret: process.env.FIELDLAB_SECRET,
    baseUrl: process.env.FIELDLAB_API_URL,
    apiUrl:
      process.env.FIELDLAB_PREFIX || `${process.env.FIELDLAB_API_URL}/user`,
  }
}

export const apiEnv = getApiEnvironment()

export const apiUrl = apiEnv.apiUrl
